@tailwind base;
@tailwind components;
@tailwind utilities;
html{
    scroll-behavior: smooth;
}
.bg-img{
    background-image: url(./assets/bgtres.webp);
    position: sticky;
}
.boton-subir-contenedor{
    position:fixed;
    bottom: 4rem;
    right: 1rem;
    width: 4rem;
    height: 4rem;
    z-index: -1;

}
.boton-subir{
    width: 0rem;
    height: 0rem;
    background-color: #2b635a;
    border-radius: 50%;
    cursor: pointer;
    transition: .2s;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}
.boton-subir svg{
    position: absolute;
    font-size: 1.7rem;
    top: 48%;
    left: 50%;
    transform: translate(-50%,-50%) scale(0);
    color: white;
    transition: .2s;

}
.show{
    z-index: 10;
}
.show .boton-subir{
    animation: popup .3s ease-in-out;
    width: 4rem;
    height: 4rem;
    z-index: 11;
}
.show svg{
    transform: translate(-50%,-50%) scale(1);
}
@keyframes popup{
    0%{
        width: 0rem;
        height: 0rme;

    }
    50%{
        width: 6rem;
        height: 6rem;
    }
    100%{
        width: 4rem;
        height: 4rem;
    }
}